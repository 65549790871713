const URL_API_BRANCHS = "allbranchs";
const URL_API_NEWS = "webnews";
const URL_API_ACTIVITY = "postwebs";
const URL_API_SEARCH = "webusers";
const URL_API_EVENTS = "webevents";
const URL_API_CLUBS = "allclubs";
const URL_API_HISTORY = "formsbytype/2";
const URL_API_BRANCH_IN_AREA = "branchsinareas";
const URL_API_ACTIVITY_HOME = "postwebsbytype/-1";
const URL_API_LIBRARY_HOME = "weblibraries";
const URL_API_TYPE_DOCUMENT = "documenttypes";
const URL_API_INFO_TYPE_DOCUMENT = "weblibrariesnew";
const URL_API_HISTORY_DETAIL = "form";
const URL_API_BANNERS = "web/banners";
const BASE_URL = "https://voduong.tekup.vn/api/v1/";
const URL_SEARCH_HISTORY = "forms-web-search";
const URL_SEARCH_OTHER = "postwebs-search";
const URL_SEARCH_INFORMATION = "information-web-search";
const URL_SEARCH_BRANCH = "webbranchs";
const GET_LIST_CATEGORY = "categories";
const URL_API_TECHNICAL = "postwebs";
const URL_API_BRANCH = "areas";
const URL_API_LIST_HISTORY = "formsbytype/2";
const URL_FACEBOOK = "https://www.facebook.com/nghiadungkhongthu/";
const URL_YOUTUBE = "https://www.youtube.com/channel/UC7F4FBGmnGpl9tC2hLAXIGg";
const URL_CLUB = "/club/";
const URL_TECH = "/group-techniques/";
const URL_TECH_DETAIL = "/details/";

// Techniques
const URL_GROUP_TECHNIQUES = "menus";

const URL_API_DETAIL_DOCUMENT = (id) => {
  return `/weblibrary/${id}`;
};

const URL_API_POSTS = (id) => {
  return `/weblibrary/${id}`;
};
const URL_API_HORNOR = (id) => {
  return "/honors/" + id;
};
const URL_API_NEWS_DETAIL = (id) => {
  return "/webnew/" + id;
};
const URL_API_EVENT_DETAIL = (id) => {
  return "/webevent/" + id;
};
const URL_API_EVENT_RELATIVED = (id) => {
  return `/events/${id}/relatives`;
};
const URL_API_NEWS_RELATIVED = (id) => {
  return `/news/${id}/relatives`;
};
const URL_API_POSTS_RELATIVED = (id) => {
  return `/libraries/${id}/relatives`;
};

//V2
const URL_API_ACTIVITY_DETAIL = (id) => {
  return "/postweb/" + id;
};
const URL_API_ACTIVITY_RELATE = (id) => {
  return "/postwebrelatives/" + id;
};
const URL_API_POSTS_LIST_TECHNICAL_ACTIVE = "postforwebs";
const URL_API_DETAIL_BRANCH = "webbranch";
const URL_API_DETAIL_TECHNICAL = "postweb";
const URL_API_RELATE_TECHNICAL = "postwebrelatives";
const URL_API_TECHNICAL_TONG_QUAN = "formsbytype/4";
const URL_API_ACTIVE_TONG_QUAN = "formsbytype/5";
const URL_API_HOME = "webhome";
const URL_API_MAPS = "allbranchs";
const URL_API_LIBRARY = "weblibraries";
const URL_API_INFO_PAGE_MAP = "formsbytype/3";
const URL_API_INFO_FORMS_BY_TYPE = "formsbytype";
const URL_API_EXAM = "webcompetitions";
const URL_API_EXAM_DETAIL = "webcompetition";
const TEXT_ERROR_API = "Something went wrong ! please try again";

const URL_API_PROVINCE = "https://vapi.vnappmob.com/api/province";
const URL_API_DISTRICT = "https://vapi.vnappmob.com/api/province/district";
const URL_API_WARD = "https://vapi.vnappmob.com/api/province/ward";
const GET_LIST_BRANCH = "allbranchs";
const GET_LIST_CLUB = "getclubs";
const URL_REGISTER = "auth/sign-up";
const URL_LOGIN = "auth/login";
const URL_WEB_NEWS = "/web-news/";

//V2
const CONTACT_INFO = "contactinfo";

const URL_ACTIVE_PAGE_V2 = "/activity-new/";

const CATALOG_LIBRARY = (tab) => {
  switch (tab) {
    case 0:
      return 3;
    case 1:
      return 5;
    case 2:
      return 1;
    case 3:
      return 4;
    default:
      return 3;
  }
};
const URL_TECHNICAL_PAGE = {
  kyThuatCanBan: "ky-thuat-can-ban",
  kyThuatQuyenPhap: "ky-thuat-quyen-phap",
  dauLuyenThiDau: "dau-luyen-va-thi-dau",
  chuongTrinhHuanLuyen: "chuong-trinh-huan-luyen",
  quyCheThiDau: "quy-che-thi-dau-va-len-dang",
};
const URL_ACTIVE_PAGE = {
  lichLuyenTap: "lich-tap-luyen",
  toChucThiDau: "to-chuc-thi-dau",
  hoatDongDaNgoai: "hoat-dong-da-ngoai",
  hoatDongTuThien: "hoat-dong-tu-thien",
  hoatDongKhac: "hoat-dong-khac",
};
const URL_HISTORY_PAGE = {
  tuOkinawaDenTokyo: "tu-okinawa-den-tokyo",
  tuNhatBanDenVietNam: "tu-nhat-ban-den-viet-nam",
  tuDaoDuongSuzuki:
    "tu-dao-duong-suzuki-dojo-noel-den-he-phai-suzucho-karate-do",
  tuSuzuchoKarateDo: "tu-suzucho-karate-do-ryu-den-nghia-dung-karate-do",
};

const URL_INFORMATION_PAGE = {
  thongTin: "thong-tin",
};

const URL_API_NEWS_RELATE = (id) => {
  return "/information/relate/" + id;
};
const URL_API_CLUBS_RELATE = (id) => {
  return "/branchsrelative/" + id;
};

const REGEX_HTMl_TAG = /(<([^>]+)>)/gi;
export {
  URL_API_HORNOR,
  TEXT_ERROR_API,
  URL_API_NEWS,
  URL_API_SEARCH,
  URL_API_EVENTS,
  URL_API_LIBRARY,
  CATALOG_LIBRARY,
  URL_API_NEWS_DETAIL,
  URL_API_EVENT_DETAIL,
  URL_API_MAPS,
  URL_API_EVENT_RELATIVED,
  URL_API_NEWS_RELATIVED,
  URL_API_HOME,
  URL_API_CLUBS,
  URL_API_POSTS,
  URL_API_POSTS_RELATIVED,
  URL_API_INFO_PAGE_MAP,
  URL_API_HISTORY,
  URL_API_TECHNICAL_TONG_QUAN,
  URL_API_POSTS_LIST_TECHNICAL_ACTIVE,
  URL_API_DETAIL_TECHNICAL,
  URL_API_RELATE_TECHNICAL,
  URL_TECHNICAL_PAGE,
  URL_ACTIVE_PAGE,
  URL_API_ACTIVE_TONG_QUAN,
  URL_HISTORY_PAGE,
  URL_API_DETAIL_BRANCH,
  URL_API_INFO_FORMS_BY_TYPE,
  URL_INFORMATION_PAGE,
  REGEX_HTMl_TAG,
  URL_API_EXAM,
  URL_API_EXAM_DETAIL,
  URL_API_PROVINCE,
  URL_API_WARD,
  URL_API_DISTRICT,
  GET_LIST_BRANCH,
  GET_LIST_CLUB,
  URL_REGISTER,
  URL_LOGIN,
  URL_API_BRANCH_IN_AREA,
  URL_API_ACTIVITY_HOME,
  URL_API_LIBRARY_HOME,
  URL_API_TYPE_DOCUMENT,
  URL_API_INFO_TYPE_DOCUMENT,
  URL_API_DETAIL_DOCUMENT,
  URL_API_HISTORY_DETAIL,
  URL_API_BANNERS,
  URL_SEARCH_HISTORY,
  URL_SEARCH_OTHER,
  URL_SEARCH_INFORMATION,
  URL_SEARCH_BRANCH,
  GET_LIST_CATEGORY,
  URL_API_TECHNICAL,
  URL_API_BRANCH,
  URL_API_LIST_HISTORY,
  URL_API_BRANCHS,
  CONTACT_INFO,
  URL_YOUTUBE,
  URL_FACEBOOK,
  URL_WEB_NEWS,
  URL_API_ACTIVITY,
  URL_GROUP_TECHNIQUES,
  URL_ACTIVE_PAGE_V2,
  URL_API_ACTIVITY_DETAIL,
  URL_API_NEWS_RELATE,
  URL_API_ACTIVITY_RELATE,
  URL_API_CLUBS_RELATE,
  URL_CLUB,
  URL_TECH,
  URL_TECH_DETAIL,
};

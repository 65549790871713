import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Select } from "antd";
import Cookies from "js-cookie";

import { changeLanguage } from "../../utils/functions";
import { postData } from "../../utils/service";
import {
  URL_ACTIVE_PAGE_V2,
  URL_API_ACTIVITY,
  URL_API_BANNERS,
} from "./../../utils/constants";

import CardInformationCommon from "./../../components/CardInformationCommon/index";
import Breadcrumb from "./../../components/BreadcrumbCommon/index";
import Pagination from "./../../components/pagination";
import BannerCommon from "../../components/BannerCommon";

import "./../../assets/styles/activity/activity.scss";

const ActivityPage = () => {
  const { t } = useTranslation("translation");

  const [data, setData] = useState([]);
  const [imageBanner, setImageBanner] = useState("");
  const [dataActivity, setDataActivity] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const [params, setParams] = useState({
    currentPage: 1,
    pageSize: 12,
    total: 0,
  });
  const [filterActivity, setFilterActivity] = useState({
    isPostCreatedLate: false,
    isPostCreatedEarly: false,
  });

  const categoryOption = [
    {
      value: "new",
      label: t("activity.newPost"),
    },
    {
      value: "old",
      label: t("activity.oldPost"),
    },
  ];

  const handleFilteractivity = (value) => {
    if (value === "new") {
      setFilterActivity({
        isPostCreatedLate: false,
        isPostCreatedEarly: true,
      });
    } else {
      setFilterActivity({
        isPostCreatedLate: true,
        isPostCreatedEarly: false,
      });
    }

    setTimeout(() => {
      const gglang = Cookies.get("googtrans");
      changeLanguage(gglang ? gglang.replace("/vi/", "") : "vi");
    }, 500);
  };

  useEffect(() => {
    postData(URL_API_ACTIVITY, {
      per_page: params?.pageSize,
      page: params?.currentPage,
      type_menu: "2",
      ...filterActivity,
    })
      .then((res) => {
        const resDatas = res?.data?.data;
        if (
          !filterActivity?.isPostCreatedEarly &&
          !filterActivity.isPostCreatedLate
        ) {
          const resData = resDatas?.sort((a, b) => {
            if (a.position !== b.position) {
              return a.position - b.position;
            }
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          setDataActivity(resData);
        } else {
          setDataActivity(resDatas);
        }
        setParams({ ...params, total: res?.data?.paginate?.total });
      })
      .catch((err) => {
        return err;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.currentPage, filterActivity]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    postData(URL_API_BANNERS, { is_show: true })
      .then((res) => {
        const imageActivity = res?.data?.data?.find(
          (item) => item.type === "OPERATION"
        );
        setImageBanner(
          isMobile ? imageActivity?.image_mobile : imageActivity?.image
        );
      })
      .catch((err) => {
        return err;
      });
  }, []);

  const handleChangeCurrentPage = (e) => {
    setParams({ ...params, currentPage: e });
  };

  return (
    <>
      <div className="wrap">
        <div className="wrap__banner">
          <BannerCommon img={imageBanner} text1={t("activity.title")} />
        </div>
        <div className="container">
          <div className="container__breadcrumb">
            <Breadcrumb
              dataBreadcrumb={[
                { title: t("activity.home"), url: "/" },
                { title: t("activity.title-detail") },
              ]}
            />
          </div>

          <div className="container__content">
            <div className="container__select">
              <Select
                placeholder={t("activity.placeholder")}
                options={categoryOption}
                onChange={handleFilteractivity}
                className="library-filter-wrap"
              />
            </div>
            <Row
              gutter={[{ xs: 8, lg: 16, sm: 16, xl: 24, md: 16, xxl: 24 }, 24]}
            >
              {dataActivity?.length > 0 &&
                dataActivity?.map((item) => {
                  return (
                    <Col xs={24} md={12} lg={8} xl={6} key={item?.id}>
                      <CardInformationCommon
                        data={{
                          header: item?.menu_name,
                          image:
                            item?.thumbnail ||
                            item?.image ||
                            item?.attach_files?.[0],
                          title: item?.title,
                          id: item?.id,
                          date: item?.created_at,
                          path: URL_ACTIVE_PAGE_V2,
                        }}
                      />
                    </Col>
                  );
                })}
            </Row>
          </div>
          <div className="container--pagination">
            <Pagination
              current={params?.currentPage}
              pageSize={params?.pageSize}
              total={params?.total}
              onChange={handleChangeCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityPage;

import React from "react";

import "../../assets/styles/detail-search/detail-item.scss";

import DefaultImageAssets from "./../../assets/images/default_image.svg";

const DetailItem = ({ data, handleViewDetail }) => {
  function isImageFile() {
    const extension =
      data?.attach_files?.[0]?.split(".")?.pop()?.toLowerCase() ||
      data?.bgr_image?.split(".")?.pop()?.toLowerCase();

    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

    return imageExtensions?.includes(extension);
  }

  return (
    <div
      className="item"
      onClick={() => handleViewDetail(data?.menu_id || data?.id)}
    >
      <div className="item__left">
        <img
          src={
            isImageFile()
              ? data?.attach_files
                ? process.env.REACT_APP_ENDPOINT + data?.attach_files?.[0]
                : process.env.REACT_APP_ENDPOINT + data?.bgr_image
              : DefaultImageAssets
          }
          alt=""
        />
      </div>
      <div className="item__right">
        <p className="item__right--title">{data?.title || data?.name}</p>
        {data?.description && (
          <p
            className="item__right--content content-custom"
            dangerouslySetInnerHTML={{
              __html: data?.description,
            }}
          />
        )}

        {data?.address && (
          <>
            <p className="item__right--content content-custom">
              Địa Chỉ: <span> {data?.address}</span>
            </p>
          </>
        )}

        <p className="item__right--more">Đọc Tiếp</p>
      </div>
    </div>
  );
};

export default DetailItem;
